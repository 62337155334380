import React from 'react';

import CardHeader from '@elements/CardHeader';
import { getClasses } from '@utils';
import { Link } from '@elements/Link';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import './style.css';

type CardArticleProps = {
  article: {
    title: string,
    slug: string,
    type: string,
    publishDate: string,
    heroImage: {
      title: string,
      gatsbyImageData: IGatsbyImageData,
    },
    categories: Array<{
      title: string,
    }>,
    country: string,
  },
  childClassName?: string,
};

const CardArticle = ({
  article,
  childClassName = '',
}: CardArticleProps): JSX.Element => {
  const {
    title,
    slug,
    publishDate,
    heroImage,
    country,
    type = '',
    categories = [],
  } = article;
  const image = getImage(heroImage?.gatsbyImageData);

  let JSXTag: JSX.Element | string = 'div';
  let href;
  if(slug){
    JSXTag = Link;
    href = `/${ type.toLowerCase() }/${slug}/`;
  }

  const mainClasses = getClasses(
    `card-article--main`,
    childClassName,
  );

  return (
    <article className={mainClasses}>
      <JSXTag to={href}>
        <section className='card-article__container'>
          <div className="card-article__image">
            {
              image && (
                <GatsbyImage
                  alt={heroImage.title}
                  className="flex-grow"
                  image={image}
                />
              )
            }
          </div>
          <CardHeader
            childClassName="card--header--article"
            topText={publishDate}
            topTextBold={categories[0]?.title || country}
          >
            <h4>{title}</h4>
          </CardHeader>
        </section>
      </JSXTag>
    </article>
  );
};

export default CardArticle;
