import React, { useState, useEffect } from 'react';

import Button from '@elements/Button';
import IconClose from '@svg/icons/close-icon.svg';
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

import './style.css';

type PopUpProps = {
  isOpen: boolean,
  onDidDismiss: () => void,
  clientEmail: string;
  content: {
    title: string,
    buttonText: string,
    description: {
      description: string
    },
    image: {
      title: string,
      gatsbyImageData: IGatsbyImageData,
    }
  },
};

const PopUp = ({
  isOpen,
  onDidDismiss,
  content,
  clientEmail = '',
}: PopUpProps): JSX.Element => {
  const {
    title,
    buttonText,
    description,
    image: preImage,
  } = content;
  const [delay, setDelay] = useState(false);

  useEffect(() => {
    setTimeout(() => setDelay(true), 250);
  }, [isOpen]);

  const handleClose = () => {
    setDelay(false);
    setTimeout(() => onDidDismiss(), 250);
  };

  const handleNotDismiss = (ev) => ev.stopPropagation();

  const image = getImage(preImage.gatsbyImageData);

  return (
    <div 
      aria-hidden="true" 
      className={`popup--main ${ delay ? 'popup--show' : '' }`}
      onClick={handleClose}
    >
      <section className='popup__container'>
        <div 
          aria-hidden="true" 
          className="popup__inner" 
          onClick={handleNotDismiss}
        >
          <section className="popup__btn-close">
            <button 
              className="btn btn--icon-only bg-gray-200" 
              type="button"
              onClick={handleClose}
            >
              <IconClose />
            </button>
          </section>
          <section className="popup__body">

            <div className="popup__image--wrapper">
              {
                image && <GatsbyImage alt={preImage.title} image={image} />
              }
            </div>

            <hr className="popup--line" />
            <h2><strong>{ title }</strong></h2>
            <p>
              { description.description }
              <strong>{ clientEmail }</strong>
            </p>
            <Button 
              childClassName="btn--secondary" 
              onClick={handleClose}
            >
              { buttonText }
            </Button>

          </section>
        </div>
      </section>
    </div>
  );
};

export default PopUp;
