import React, { useState, useRef, HTMLProps } from 'react';
import HubspotForm from 'react-hubspot-form';

import PopUp from '@elements/PopUp';
import RichText from '@elements/RichText';
import { changeTextPlaceholder, getClientEmail } from '@utils';
import { graphql, useStaticQuery } from 'gatsby';

import './style.css';

type FormProps = {
  item: {
    __typename: string;
    contentful_id: string;
    classes: string;
    icons: Array<{
      hubspotId: string;
      iconId: string;
    }>;
    title: string;
    subtitle: string;
    submitButtonColor: string;
    hubSpotFormId: string;
    richText: {
      raw: string;
      references: Array<
        | {
            image: {
              title: string;
            };
          }
        | any
      >;
    };
    modal: {
      title: string;
      buttonText: string;
      fullDescription: {
        fullDescription: string;
      };
      image: {
        title: string;
      };
    };
  };
};

type InputProps = HTMLProps<HTMLAllCollection> & {
  labels: {
    textContent: string;
  };
  value: string;
};

type FormRefProps = {
  current: {
    el: {
      firstChild: InputProps[];
    };
  };
};

const Form = ({ item }: FormProps): JSX.Element => {
  const { classes = '', richText, icons, title, subtitle, modal, hubSpotFormId } = item;

  const formRef: FormRefProps = useRef();
  const [showPopUp, setShowPopUp] = useState(false);
  const [clientEmail, setClientEmail] = useState('');

  const { site } = useStaticQuery(graphql`
    query QUERY_GET_HUBSPOT_ID {
      site {
        siteMetadata {
          hubSpotPortalId
          title
        }
      }
    }
  `);

  const handleReadyForm = (): void => {
    changeTextPlaceholder(formRef, icons);
  };

  const handleSubmitForm = (): void => {
    const email = getClientEmail(formRef);
    setClientEmail(email);
    setShowPopUp(true);
  };

  return (
    <>
      <div className={`form--main ${classes}`}>
        <div className='form__container'>
          <section className='form__left-side'>
            <>
              <hr />
              {richText && <RichText bodyRichText={richText} />}
            </>
          </section>

          <section className='form__inner'>
            {title && (
              <>
                <hr />
                <h2>{title}</h2>
              </>
            )}
            {subtitle && <h3>{subtitle}</h3>}
            <HubspotForm
              ref={formRef}
              formId={hubSpotFormId}
              loading={<div>Loading...</div>}
              portalId={site?.siteMetadata?.hubSpotPortalId}
              onReady={handleReadyForm}
              onSubmit={handleSubmitForm}
            />
          </section>
        </div>
      </div>
      {showPopUp && (
        <PopUp clientEmail={clientEmail} content={modal} isOpen={showPopUp} onDidDismiss={() => setShowPopUp(false)} />
      )}
    </>
  );
};

export default Form;
