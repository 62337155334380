import React from 'react';

import RichText from '@elements/RichText';
import SocialIcons from '@elements/SocialIcons';

import './style.css';

type ArticleBodyProps = {
  article: {
    contentful_id: string,
    body: string,
    title: string,
    type: string,
    slug: string,
    categories,
    publishDate: string,
  }
}

const ArticleBody = ({
  article: {
    contentful_id,
    body,
    title,
    type,
    slug,
    categories,
    publishDate,
  },
}: ArticleBodyProps): JSX.Element => {

  const socialList = {
    titles: [
      'e-mail',
      'linkedIn',
      'facebook',
      'twitter',
    ],
    commonLink: `/${ type.toLowerCase() }/${slug}/`,
    type: 'share',
    id: contentful_id,
  };

  return (
    <div className="article--body">
      <div className="article__header">
        <hr />
        <h1> { title } </h1>
        <p>
          <b>{ categories?.[0]?.title }</b>
          { publishDate }
        </p>
      </div>
      <div className="article__body-inner">
        {
          body && <RichText bodyRichText={body} />
        }
        {/* <strong>{ publishDate }</strong> */}
      </div>
      <div className="article__social">
        <SocialIcons socialList={socialList} />
      </div>
    </div>
  );
};

export default ArticleBody;
