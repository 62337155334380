import React from 'react';

import ArticleBody from '@elements/ArticleBody';
import CardArticle from '@elements/CardArticle';
import Carousel from '@elements/Carousel';
import Form from '@sections/Form';
import { getRelatedArticles } from '@utils';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import '../styles/templates/article.css';

type ItemProps = {
  __typename: string;
  contentful_id: string;
  title: string;
  heroImage: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  };
  categories: Array<{
    title: string;
  }>;
};

type ArticleProps = ItemProps & {
  relatedArticles: ItemProps[];
};

type FormProps = {
  __typename: string;
  contentful_id: string;
  title: string;
};

type PageProps = {
  data: {
    article: ArticleProps;
    subscribeForm: FormProps;
    latestBlogs: {
      nodes: ArticleProps[];
    };
    articleOverview;
  };
};

const Article: React.FC<PageProps> = ({ data }) => {
  const {
    article,
    latestBlogs,
  } = data;

  const image = getImage(article?.heroImage?.gatsbyImageData);
  const relatedPosts = getRelatedArticles(article.relatedArticles, latestBlogs.nodes, 6);
  const breakpoints = {
    sm: 2,
    lg: 3,
  };

  return (
    <>
      <div className='article__top'>
        <section className='article__image-wrapper'>
          <div>
            <GatsbyImage alt={article?.heroImage?.title} image={image} loading='eager' />
          </div>
        </section>
        <section className='article__container'>
          <ArticleBody article={article} />
        </section>
      </div>
      <div className='article__related-blogs'>
        <div className='article__related-blogs__title'>
          <hr />
          <h2> Related Blog Post stories </h2>
        </div>
        <Carousel breakpoints={breakpoints}>
          {relatedPosts?.map((oneArticle) => (
            <CardArticle key={oneArticle?.contentful_id} article={oneArticle} />
          ))}
        </Carousel>
      </div>
      <div className='article__subscribe-form mt-8'>
        {data?.articleOverview?.subscribeForm && (
          <Form item={data.articleOverview.subscribeForm} />
        )}
      </div>
    </>
  );
};

export default Article;

export const articleQuery = graphql`
  query QueryArticle($contentful_id: String!, $type: String!) {
    navigation: contentfulNavigationNavMenu(
      contentful_id: { eq: "28QWR4Rx641pUxsQaVNB1m" }
    ) {
      ...NavMenu
    }

    seo: contentfulArticle(contentful_id: { eq: $contentful_id }) {
      ...ArticleSeo
    }

    article: contentfulArticle(contentful_id: { eq: $contentful_id }) {
      ...ArticleFull
    }

    latestBlogs: allContentfulArticle(
      limit: 6
      sort: { fields: publishDate, order: DESC }
      filter: {
        contentful_id: { ne: $contentful_id }
        heroImage: { id: { ne: null } }
        type: { ne: null }
        title: { ne: null }
        slug: { ne: null }
      }
    ) {
      nodes {
        ...ArticleWithImage
      }
    }

    articleOverview: contentfulArticleOverview(
      type: { eq: $type }
      slug: { regex: "/^((?!placeholder-not-erase).)*$/" }
    ) {
      subscribeForm: form {
        ...FormFull
      }
    }
  }
`;
